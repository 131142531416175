// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <div className="d-flex justify-content-center text-light bg-dark position-fixed fixed-bottom">
        Copyright &copy; {year}
      </div>
    </>
  )
}

export default Footer
