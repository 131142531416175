import PropTypes from "prop-types"
import React, { Component } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { Link } from "gatsby"
import * as header from "styles/header.module.scss"

const navStylePill = {
  borderRadius: "40px",
}

const navStyleBox = {
  borderRadius: 0,
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sitenavStyle: {},
      isTop: true,
      handledHash: false,
    }
  }

  componentDidMount = () => {
    if (this.props.fixed === "top") {
      document.body.style.paddingTop = `${this.navbar.offsetHeight + 8}px`
    } else if (this.props.fixed === "bottom") {
      document.body.style.paddingBottom = `${this.navbar.offsetHeight + 8}px`
    }
    document.addEventListener("scroll", this.onScroll)
  }
  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.onScroll)
  }

  onScroll = (event) => {
    if (this.state.isTop && window.scrollY > 1 * this.navbar.offsetHeight) {
      // clear the pill background, go full width
      this.setState({ sitenavStyle: navStyleBox, isTop: false })
      this.navbar.classList.add("p-0")
      this.navrow.classList.add("w-100")
      this.navbar.classList.add("bg-dark")
    } else if (
      !this.state.isTop &&
      window.scrollY <= 1 * this.navbar.offsetHeight
    ) {
      this.setState({ sitenavStyle: navStylePill, isTop: true })
      this.navbar.classList.remove("p-0")
      this.navrow.classList.remove("w-100")
      this.navbar.classList.remove("bg-dark")
    }
  }

  render = () => {
    return (
      <>
        <Navbar
          ref={(e) => (this.navbar = e)}
          expand="sm"
          collapseOnSelect
          className={`${header.navAnimations} ${header.navbarLocal}`}
          variant="dark"
          fixed={this.props.fixed}
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <div
            className={
              header.animations + " container-fluid justify-content-center p-0"
            }
          >
            <div
              ref={(e) => (this.navrow = e)}
              className={[header.animations, header.navrow, "row"].join(" ")}
            >
              <Navbar.Collapse
                id="basic-navbar-nav"
                className={`${header.sitenav} p-2 justify-content-center font-weight-bold`}
                style={this.state.sitenavStyle}
              >
                <Nav className={header.sitemenu}>
                  <Nav.Item>
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </Nav.Item>
                  {/*<Nav.Item><Link className="nav-link" to="/tech-notes">Tech Notes</Link></Nav.Item>*/}
                  {/*<Nav.Item><Link className="nav-link" to="/articles">Articles</Link></Nav.Item>*/}
                  <Nav.Item>
                    <Link className="nav-link" to="/about">
                      About
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link className="nav-link" to="/#contactme">
                      Contact
                    </Link>
                  </Nav.Item>
                  {/* <Nav.Item><a href="/#contactme" className="nav-link">Contact</a></Nav.Item> */}
                </Nav>
              </Navbar.Collapse>
            </div>
          </div>
        </Navbar>
        <div style={{ height: this.props.contentOffset + "px" }}></div>
      </>
    )
  }
}

Header.propTypes = {
  contentOffset: PropTypes.number,
  fixed: PropTypes.string,
}

Header.defaultProps = {
  contentOffset: 0,
  fixed: "top",
}

export default Header
