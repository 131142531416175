/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import ScriptFooter from "./scriptfooter"
import "styles/global.scss"
import "styles/mungegatsby.scss"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lastHash: "",
    }
  }
  // componentDidMount = () => {
  //   console.log('layout did mount');
  //   console.log(`hash in layout did mount is ${window.location.hash}`);
  // }

  // componentDidUpdate = () => {
  //   if (window.location.hash !== this.state.lastHash) {
  //     console.log('hash needs updating');
  //     console.log(`last ${this.state.lastHash}`);
  //     console.log(`current ${window.location.hash}`);
  //     this.setState({lastHash: window.location.hash});
  //   }
  // }

  render = () => {
    return (
      <>
        <Header />
        {this.props.children}
        <Footer />
        <ScriptFooter />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
